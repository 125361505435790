export const themes = {
  primary: {
    normal: '#6666FF',
    light: '#C1C1FF',
    dark: '#3C3CC1',
  },
  secondary: {
    normal: '#60E1E0',
    light: '#ADFFFE',
    dark: '#34ABAA',
  },
  grayscale: {
    black: '#000000',
    white: '#FFFFFF',
    fa: '#fafafa',
    f5: '#f5f5f5',
    f0: '#f0f0f0',
    d9: '#d9d9d9',
    bf: '#bfbfbf',
    '8c': '#8c8c8c',
    '59': '#595959',
    '43': '#434343',
    '26': '#262626',
    '1f': '#1f1f1f',
    '14': '#141414',
  },
  system: {
    error: '#d32f2f',
  },
};
