import React, { useRef } from 'react';
import { motion, useAnimation, useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import bondLogo from 'assets/images/bond-logo-white.png';
import backgroundImage from 'assets/images/background-image-swipe.png';
import arrowImage from 'assets/images/arrow.png';
import twinkleImage from 'assets/images/twinkle.png';
import { themes } from 'utils/themes';
import useWindowSize from 'hooks/useWindowSize';

export default function VideoTransitionSection() {
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: sectionRef });
  const [screenWidth, screenHeight] = useWindowSize();
  const isDesktop = screenWidth > 600;
  const circleControls = useAnimation();
  const circleTextControls = useAnimation();
  const backgroundControls = useAnimation();
  const mainTextControls = useAnimation();

  const mobileCircleControls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.9 });

  React.useEffect(() => {
    if (isDesktop) {
      // 데스크탑 & 태블릿
      if (inView) {
        circleControls.start({
          width: '300vw',
          height: '300vw',
          x: '300px',
          opacity: 1,
          transition: { duration: 4 },
        });
        circleTextControls.start({ opacity: 0, transition: { duration: 1 } });
        mainTextControls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 1.0, delay: 0.7 },
        });
        backgroundControls.start({
          opacity: 1,
          x: 0,
          transition: { duration: 1.0, delay: 0.5 },
        });
      } else {
        if (scrollYProgress.get() > 0.1) return;
        circleControls.start({
          width: '25vw',
          height: '25vw',
          x: 0,
          y: 0,
          opacity: 1,
        });
        circleTextControls.start({ opacity: 1 });
        mainTextControls.start({ opacity: 0, y: 100 });
        backgroundControls.start({ opacity: 0, x: 50 });
      }
    } else {
      // 모바일
      if (inView) {
        mobileCircleControls.start({
          width: '300vw',
          height: '300vw',
          bottom: '-10vh',
          transition: { duration: 1.5 },
        });
        circleTextControls.start({ opacity: 0, transition: { duration: 1 } });
        mainTextControls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 1.0, delay: 0.5 },
        });
      } else {
        if (scrollYProgress.get() > 0.1) return;
        mobileCircleControls.start({
          width: '35vw',
          height: '35vw',
          bottom: '20vh',
        });
        circleTextControls.start({ opacity: 1 });
        mainTextControls.start({ opacity: 0, y: 100 });
      }
    }
  }, [backgroundControls, inView, scrollYProgress, screenWidth]);

  return (
    <div
      ref={sectionRef}
      className={`w-[100vw] h-[150vh] flex justify-between relative bg-[${themes.grayscale['1f']}]`}
    >
      <div
        ref={ref}
        className="w-full h-[100vh] sticky top-0 flex items-center overflow-hidden "
      >
        {isDesktop ? (
          <>
            <motion.img
              className="w-auto h-screen desktop:w-screen desktop:h-screen  desktop:min-w-[1720px] absolute right-0 desktop:-right-[10vw] z-20"
              src={backgroundImage}
              animate={backgroundControls}
              initial={{ opacity: 0, x: 50 }}
              style={{ objectFit: 'cover' }}
            />
            <div className="bg-black rounded-full -left-1/2 transform translate-x-1/2 absolute z-0">
              <div className={`w-[50vw] h-[50vw] rounded-full relative`}>
                <div className="text-center absolute top-1/2 right-1/4 transform translate-x-1/2 -translate-y-1/2 z-10 ">
                  <h3 className="font-suitMedium text-white text-center text-[32px] desktop:text-[64px]">
                    댓글
                  </h3>
                  <span className="font-suitBold text-white text-[16px] desktop:text-[24px]">
                    Comment
                  </span>
                </div>
                <img
                  className="absolute top-1/2 w-[200px] desktop:w-[320px] -right-[240px] desktop:-right-[400px] transform -translate-y-1/2 "
                  src={arrowImage}
                />
              </div>
            </div>
            <motion.div
              animate={circleControls}
              transition={{ duration: 1 }}
              initial={{ opacity: 1, x: 0, y: 0 }}
              className="responsive-class bg-primary-normal w-[25vw] h-[25vw] rounded-full absolute right-[112px] flex justify-center items-center z-10"
            >
              <motion.div animate={circleTextControls} className="text-center">
                <h3 className="font-suitBold text-white text-[32px] desktop:text-[64px]">
                  영상
                </h3>
                <span className="font-suitBold text-white text-[16px] desktop:text-[24px]">
                  Video
                </span>
              </motion.div>
            </motion.div>
            <motion.div
              animate={mainTextControls}
              initial={{
                x: '-45%',
              }}
              className="responsive-class flex flex-col w-[50vw] min-w-[450px] desktop:min-w-[570px] px-10 text-center absolute left-1/4 transform -translate-x-1/4 text-3xl font-suitMedium max-w-[756px] text-neutral-100 z-30"
            >
              <img
                src={twinkleImage}
                className="w-[80px] absolute -top-[88px] right-[32px]"
              />
              <img
                loading="lazy"
                src={bondLogo}
                alt=""
                className="self-center w-[227px]"
              />
              <h2 className="font-suitMedium w-full text-center text-[24px]">
                릴레이 방식의 AI 댓영상으로
                {screenWidth < 1400 && <br />} 진정한 멀티미디어 소통의 완성
              </h2>
              <div className="absolute -bottom-[120px] left-12">
                <img src={twinkleImage} className="w-[40px]" />
                <img
                  src={twinkleImage}
                  className="w-[40px] self-start ml-[80px]"
                />
              </div>
            </motion.div>
          </>
        ) : (
          <>
            <div className="bg-black rounded-full left-1/2 -top-[470px] transform -translate-x-1/2 translate-y-1/2  absolute z-0">
              <div className={`w-[470px] h-[470px] rounded-full relative`}>
                <div className="text-center absolute bottom-1/4 right-1/2 transform translate-x-1/2 translate-y-1/4 z-10 ">
                  <h3 className="text-white text-center text-[32px] sm:text-[40px] desktop:text-[64px]">
                    댓글
                  </h3>
                  <span className="text-white text-[16px] desktop:text-[24px]">
                    Comment
                  </span>
                </div>
                <img
                  className={`w-[30vw] absolute ${screenHeight > 750 ? 'top-[130%]' : 'top-[120%]'} left-1/2 transform `}
                  style={{
                    transform: 'translate(-50%, -50%) rotate(90deg)',
                  }}
                  src={arrowImage}
                />
              </div>
            </div>
            <motion.div
              animate={mobileCircleControls}
              transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
              className="responsive-class bg-primary-normal w-[256px] h-[256px] rounded-full absolute bottom-[80px] right-1/2 transform translate-x-1/2 flex justify-center items-center"
            >
              <motion.div
                animate={circleTextControls}
                className="responsive-class text-center"
              >
                <h3 className="text-white text-[32px] desktop:text-[64px]">
                  영상
                </h3>
                <span className="text-white text-[16px] desktop:text-[24px]">
                  Video
                </span>
              </motion.div>
            </motion.div>
            <motion.div
              animate={mainTextControls}
              initial={{
                opacity: 0,
                x: isDesktop ? '-40%' : '-50%',
                y: 0,
              }}
              className="responsive-class flex flex-col gap-2  absolute w-[80%] left-1/2 transform -translate-x-1/2 z-30"
            >
              <img src={twinkleImage} className="w-[42px] self-end" />
              <img
                loading="lazy"
                src={bondLogo}
                alt=""
                className=" self-center w-[176px]"
              />
              <h2 className="font-suitMedium w-[95%] max-w-[300px] self-center text-center text-[20px] text-neutral-100">
                릴레이 방식의 AI 댓영상으로 진정한 멀티미디어 소통의 완성
              </h2>
              <img src={twinkleImage} className="w-[24px] self-start" />
              <img
                src={twinkleImage}
                className="w-[24px] self-start ml-[24px]"
              />
            </motion.div>
          </>
        )}
      </div>
    </div>
  );
}
