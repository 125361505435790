import React, { useEffect } from 'react';
import './App.css';

import PageRouter from 'layout/PageRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import Navigation from 'layout/Navigation';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <div className="App responsive-class">
        <Navigation />
        {/* <Banner /> */}
        {/* <UpdateBanner /> */}
        <PageRouter />
      </div>
    </Router>
  );
}

export default App;
