import React from 'react';
import OurServicesCoverSection from 'components/OurServices/OurServicesCoverSection';
import VideoDescriptionSection from 'components/OurServices/VideoDescriptionSection';
import VideoTransitionSection from 'components/OurServices/VideoTransitionSection';
import HistorySection from 'components/OurServices/HistorySection';
import ContactSection from 'components/OurServices/ContactSection';

export default function OurServices() {
  return (
    <>
      <OurServicesCoverSection />
      <VideoDescriptionSection />
      <VideoTransitionSection />
      <HistorySection />
      <ContactSection />
    </>
  );
}
