import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { themes } from 'utils/themes';
import useWindowSize from 'hooks/useWindowSize';

export default function HistorySection() {
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: sectionRef });
  const [screenWidth] = useWindowSize();

  const heightScale = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  // 색상 변화를 정의하는 variants
  const pointVariants = {
    initial: { backgroundColor: themes.grayscale['8c'] },
    visible: {
      backgroundColor: themes.primary.normal,
      transition: { duration: 0.5 },
    },
  };
  const [firstPoint, setFirstPoint] = useState('initial');
  const [secondPoint, setSecondPoint] = useState('initial');
  const [thirdPoint, setThirdPoint] = useState('initial');
  const firstTextOpacity = useTransform(
    scrollYProgress,
    [0, 0.24, 0.25],
    [0.5, 0.5, 1],
  );

  const secondTextOpacity = useTransform(
    scrollYProgress,
    [0, 0.49, 0.5],
    [0.5, 0.5, 1],
  );

  const thirdTextOpacity = useTransform(
    scrollYProgress,
    [0, 0.69, 0.7],
    [0.5, 0.5, 1],
  );

  useEffect(() => {
    const updatePoint = () => {
      const progress = scrollYProgress.get();

      if (progress > 0.25) {
        setFirstPoint('visible');
      } else {
        setFirstPoint('initial');
      }
      if (progress > 0.5) {
        setSecondPoint('visible');
      } else {
        setSecondPoint('initial');
      }
      if (progress > 0.75) {
        setThirdPoint('visible');
      } else {
        setThirdPoint('initial');
      }
    };
    const unsubscribe = scrollYProgress.onChange(updatePoint);

    return () => unsubscribe(); // 컴포넌트가 언마운트되면 구독을 취소합니다.
  }, [scrollYProgress]);

  return (
    <div
      ref={sectionRef}
      className="w-[100vw] h-[150vh] flex flex-col justify-end items-center bg-grayscale-1f relative"
    >
      <div className="absolute top-0 w-[1px] h-[150vh] bg-grayscale-8c">
        <motion.div
          style={{ scaleY: heightScale, transformOrigin: 'top' }}
          transition={{ duration: 0.1 }}
          className="w-[1px] bg-primary-normal h-[150vh]"
        />
      </div>
      <div className="h-[25%] flex gap-2 desktop:gap-5 justify-between self-center max-w-full max-md:flex-wrap ">
        <div className="w-[43vw] desktop:w-[40vw]" />
        <motion.div
          variants={pointVariants}
          animate={firstPoint}
          className={`shrink-0 self-start w-4 h-4 desktop:w-6 desktop:h-6 rounded-full z-10`}
        />
        <motion.div
          style={{ opacity: firstTextOpacity }}
          className="flex flex-col px-1 desktop:px-5 w-[43vw] desktop:w-[40vw] gap-2 desktop:gap-4"
        >
          <div className="text-[24px] desktop:text-[40px] font-suitBold text-white max-md:max-w-full">
            진짜 리액션
          </div>
          <div className="desktop:gap-0 text-[12px] desktop:text-[24px] leading-3 desktop:leading-5 font-suitMedium text-grayscale-d9">
            <p className="leading-4">MUTIMEDIA 댓글 </p>
            <br />
            <p className="leading-4">현실에서나 통하던 애드립, 멘트</p>
            <br />
            <p className="leading-4">개성있는 리액션</p>
            <br />
            <p className="leading-4">
              주목받지 못했던 크리에이터들의 {screenWidth < 600 && <br />}새로운
              기회
            </p>
          </div>
        </motion.div>
      </div>
      <div className="h-[25%] flex gap-2 sm:gap-5 justify-between self-center max-w-full text-right ">
        <motion.div
          style={{ opacity: secondTextOpacity }}
          className="flex flex-col px-1 desktop:px-5 w-[43vw] desktop:w-[40vw] gap-2 desktop:gap-4"
        >
          <motion.div className="self-end text-[24px] desktop:text-[32px] xl:text-[40px] font-suitBold text-white">
            AI 페르소나
          </motion.div>
          <motion.div className="desktop:gap-0 text-[12px] desktop:text-[24px] leading-3 desktop:leading-5 font-suitMedium text-grayscale-d9 text-right">
            <p className="text-right">사용자의 얼굴과 목소리를 학습</p>
            <br />
            <p className="text-right">텍스트를 영상으로</p>
            <br />
            <p className="text-right">현실과 조금은 다른 자아 발현</p>
            <br />
            <p className="text-right">참여는 간단하게, 영향력은 크게</p>
          </motion.div>
        </motion.div>
        <motion.div
          variants={pointVariants}
          animate={secondPoint}
          className="shrink-0 self-start w-4 h-4 desktop:w-6 desktop:h-6 rounded-full z-10"
        />
        <div className="w-[43vw] desktop:w-[40vw]" />
      </div>
      <div className="h-[25%] flex gap-2 desktop:gap-5 justify-between self-center max-md:flex-wrap">
        <div className="w-[43vw] desktop:w-[40vw]" />
        <motion.div
          variants={pointVariants}
          animate={thirdPoint}
          className="shrink-0 self-start w-4 h-4 desktop:w-6 desktop:h-6 rounded-full z-10"
        />
        <motion.div
          style={{ opacity: thirdTextOpacity }}
          className="flex flex-col px-1 desktop:px-5 w-[43vw] desktop:w-[40vw] gap-2 desktop:gap-4"
        >
          <div className="text-[24px] desktop:text-[40px] font-suitBold text-white">
            컨텐츠 지분화
          </div>
          <div className="desktop:gap-0 text-[12px] desktop:text-[24px] leading-3 desktop:leading-5 font-suitMedium text-grayscale-d9">
            <p className="leading-4">컨텐츠 소유권 확대</p>
            <br />
            <p className="leading-4">
              본 컨텐츠에 이어 붙이는 {screenWidth < 600 && <br />}릴레이 방식
            </p>
            <br />
            <p className="leading-4">
              댓영상을 통한 컨텐츠의 {screenWidth < 600 && <br />}실시간 재생산
            </p>
            <br />
            <p className="leading-4">누구나 참여 가능</p>
            {screenWidth > 600 && <br />}
            <p className="leading-4">가벼운 참여만으로 수익 창출</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
