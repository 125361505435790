import React, { useEffect, useRef } from 'react';
import addImage from 'assets/images/add.png';

import phoneCard2 from 'assets/images/iphone-card-2.webp';
import phoneCard3 from 'assets/images/iphone-card-3.webp';
import phoneCard4 from 'assets/images/iphone-card-4.webp';
import phoneCard5 from 'assets/images/iphone-card-5.webp';
import phoneCard6 from 'assets/images/iphone-card-6.webp';
import { useInView } from 'react-intersection-observer';
import { AnimationControls, motion, useScroll } from 'framer-motion';
import useWindowSize from 'hooks/useWindowSize';

type CardSectionProps = {
  inView: boolean;
  phoneContainerControls: AnimationControls;
  card1Control: AnimationControls;
  card2Control: AnimationControls;
  card3Control: AnimationControls;
  card4Control: AnimationControls;
  card5Control: AnimationControls;
  relayTextXControl: AnimationControls;
  fadeOutCardControl: AnimationControls;
  fadeInTextControl: AnimationControls;
};
export default function CardSection(props: CardSectionProps) {
  const {
    card1Control,
    card2Control,
    card3Control,
    card4Control,
    card5Control,
    relayTextXControl,
    fadeOutCardControl,
    fadeInTextControl,
  } = props;
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth <= 600;
  const isDesktop = screenWidth > 1080;
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
  });

  const [ref, inViewPhone] = useInView({
    threshold: 0.4,
  });
  const cardControlers = [
    card1Control,
    card2Control,
    card3Control,
    card4Control,
    card5Control,
  ];
  useEffect(() => {
    if (inViewPhone) {
      const locationX = isDesktop ? 0 : isMobile ? -400 : -500;

      card1Control.start({ x: -600 + locationX, opacity: 1, rotate: -5 });
      card2Control.start({ x: -400 + locationX, opacity: 1, rotate: 5 });
      card3Control.start({ x: -200 + locationX, opacity: 1, rotate: -5 });
      card4Control.start({ x: 0 + locationX, opacity: 1, rotate: 5 });
      card5Control.start({ x: 200 + locationX, opacity: 1, rotate: -5 });
      relayTextXControl.start({
        opacity: 1,
        x: isDesktop ? 500 : '-9%',
        transition: { delay: 1, duration: 0.5 },
      });
      fadeOutCardControl.start({ opacity: 0, transition: { delay: 2.5 } });
      fadeInTextControl.start({ opacity: 1, transition: { delay: 2.5 } });
    } else {
      if (scrollYProgress.get() > 0.1) return;
      card1Control.start({ x: 2000, opacity: 0 });
      card2Control.start({ x: 2000, opacity: 0 });
      card3Control.start({ x: 2000, opacity: 0 });
      card4Control.start({ x: 2000, opacity: 0 });
      card5Control.start({ x: 2000, opacity: 0 });
      relayTextXControl.start({ opacity: 0, transition: { delay: 0 } });
      fadeOutCardControl.start({ opacity: 1, transition: { delay: 0 } });
      fadeInTextControl.start({ opacity: 0, transition: { delay: 0 } });
    }
  }, [screenWidth, inViewPhone, scrollYProgress]);

  return (
    <div ref={sectionRef} className="w-[100vw] h-[110vh] ">
      <div
        ref={ref}
        className={`w-full h-full flex items-start justify-center relative overflow-hidden bg-grayscale-1f`}
      >
        <motion.div
          animate={fadeOutCardControl}
          transition={{ duration: 0.5, delay: 2 }}
          className="w-[500px] flex items-center absolute top-1/4 responsive-class"
        >
          <motion.div
            initial={{ x: 2000, opacity: 1 }}
            transition={{
              duration: isDesktop ? 1.5 : 2,
            }}
            animate={cardControlers[0]}
            className="relative responsive-class"
          >
            <img
              src={addImage}
              className="w-[60px] absolute top-1/2 transform -translate-x-1/2"
            />
            <img
              src={phoneCard2}
              alt={'Phone Card 2'}
              className={`w-[20vw] max-w-[230px] min-w-[200px]`}
            />
          </motion.div>
          {[phoneCard3, phoneCard4, phoneCard5, phoneCard6].map(
            (src, index) => (
              <motion.img
                key={src}
                src={src}
                alt={`Phone Card ${index + 2}`}
                initial={{ x: 2000, opacity: 1 }}
                className={`w-[20vw] max-w-[230px] min-w-[200px] absolute responsive-class`}
                transition={{
                  duration: isDesktop ? 1.5 : 2,
                }}
                animate={cardControlers[index + 1]}
              />
            ),
          )}
          <motion.div
            initial={{
              opacity: 0,
              x: 500,
            }}
            animate={relayTextXControl}
            transition={{
              duration: 0.8,
            }}
            className="absolute responsive-class"
          >
            <p className="min-w-[600px] font-suitBold text-[24px] desktop:text-[48px] text-center tracking-[0] leading-[normal]">
              <span className="text-white">
                본 영상에 릴레이로,
                <br />
                그리고 합쳐친{' '}
              </span>
              <span className="text-[#6666ff]">하나의 영상</span>
            </p>
          </motion.div>
        </motion.div>
        <motion.div
          animate={fadeInTextControl}
          transition={{ duration: 0.5, delay: 3 }}
          initial={{ opacity: 0 }}
          className="w-[100vw] text-[32px] desktop:text-[64px] font-suitBold text-center text-white absolute top-1/2 left-1/2 transform -translate-x-1/2"
        >
          참여 해보세요
        </motion.div>
      </div>
    </div>
  );
}
