// 전화번호 포맷팅 함수
export function formatPhoneNumber(phoneNumber: string) {
  const digits = phoneNumber.replace(/\D/g, ''); // 숫자가 아닌 모든 문자 제거
  if (digits.length <= 3) {
    return digits;
  } else if (digits.length <= 7) {
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  } else {
    return `${digits.slice(0, 3)}-${digits.slice(3, 7)}-${digits.slice(7, 11)}`;
  }
}

// 전화번호 유효성 검사 함수
export function validatePhoneNumber(phoneNumber: string) {
  const regex =
    /^(01[016789]-\d{3,4}-\d{4}|02-\d{3}-\d{4}|0[3-9]\d{1}-\d{3,4}-\d{4})$/;
  return regex.test(phoneNumber);
}
