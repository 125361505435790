import * as yup from 'yup';

const phoneRegExp = /^010-\d{4}-\d{4}$/;

export type UserMetadataType = {
  phoneNumber: string;
  isPrivacyChecked?: boolean;
};

// 유효성 검사 스키마 정의
export const schema = yup
  .object({
    phoneNumber: yup
      .string()
      .required('전화번호를 입력해주세요.')
      .matches(phoneRegExp, '전화번호를 확인해 주세요.'),
    isPrivacyChecked: yup.boolean().oneOf([true], '개인정보 수집 동의(필수)'),
  })
  .required();
