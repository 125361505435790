import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import bondLogoImage from 'assets/images/bond-logo.png';
import twinkleImage from 'assets/images/twinkle.png';
import micIcon from 'assets/icons/mic.png';
import talkIcon from 'assets/icons/talk.png';
import { themes } from 'utils/themes';
import phoneCard1 from 'assets/images/iphone-card-1.webp';
import bondLogoWhite from 'assets/images/bond-logo-white.png';
import bondStickerRing from 'assets/images/bond-sticker-ring.png';

import cursor from 'assets/images/cursor.webp';
import cursorClick from 'assets/images/cursor-click.webp';
import bondLogo from 'assets/images/bond-background-logo.png';
import wave from 'assets/images/wave.svg';
import downArrow from 'assets/images/down-arrow.svg';

import checkboxIcon from 'assets/icons/checkbox.png';
import checkboxActiveIcon from 'assets/icons/checkbox-active.png';
import errorIcon from 'assets/icons/error.png';

import useWindowSize from 'hooks/useWindowSize';
import CardSection from './CardSection';
import { useForm } from 'react-hook-form';
import { UserMetadataType, schema } from 'utils/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatPhoneNumber } from 'utils/phoneNumberFunc';
import { postUserMetadata } from 'utils/api';

export default function OurServicesCoverSection() {
  const sectionRef = useRef(null);
  const [screenWidth] = useWindowSize();
  const isMobile = screenWidth <= 600;
  const isDesktop = screenWidth >= 1080;

  const { scrollYProgress } = useScroll({
    target: sectionRef,
  });
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [refFirst, inViewPhone] = useInView({
    threshold: 0.1,
  });

  const subLogoControls = useAnimation();
  const logoControls = useAnimation();
  const formControls = useAnimation();
  const phoneControls = useAnimation();

  const bannerControls = useAnimation();
  const inputControls = useAnimation();
  const cursorControls = useAnimation();
  const phoneContainerControls = useAnimation();
  const backgroundLogoControls = useAnimation();

  const card1Control = useAnimation();
  const card2Control = useAnimation();
  const card3Control = useAnimation();
  const card4Control = useAnimation();
  const card5Control = useAnimation();
  const relayTextXControl = useAnimation();
  const fadeOutCardControl = useAnimation();
  const fadeInTextControl = useAnimation();

  // 초기 커서 이미지를 일반 커서로 설정
  const [currentCursor, setCurrentCursor] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePrivacyCheck = () => {
    setIsPrivacyChecked(!isPrivacyChecked);
    setValue('isPrivacyChecked', !isPrivacyChecked, { shouldValidate: true });
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<UserMetadataType>({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: '',
      isPrivacyChecked: false,
    },
  });

  // 유저 정보 폼 제출
  const onSubmit = async (formData: UserMetadataType) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await postUserMetadata(formData, 'top');
      alert('등록 완료. 출시되면 알려드릴게요!');
      reset({
        phoneNumber: '',
        isPrivacyChecked: false,
      });
      setIsPrivacyChecked(false);
    } catch (error) {
      console.error('POST USER METADATA FAIL : ', error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    // 스크롤 애니메이션
    if (inViewPhone) {
      bannerControls.start({ y: 200, opacity: 0 });
      backgroundLogoControls.start({
        opacity: 1,
        y: 0,
      });
      inputControls.start({
        y: isDesktop
          ? screenWidth < 1650
            ? '20%' // 1080px 이상 1650px 미만
            : '60%' // 1650px 이상
          : '-80%', // 1080px 이하
        opacity: 1,
      });
      cursorControls.start({
        x: 0,
        y: isDesktop ? '15%' : 0,
        opacity: 1,
      });
      phoneContainerControls.start({
        y: isDesktop
          ? screenWidth < 1650
            ? '-55%' // 1080px 이상 1650px 미만
            : '-50%' // 1650px 이상
          : '-80%', // 1080px 이하
        transition: { duration: 0.5 },
      });
    } else {
      if (scrollYProgress.get() > 0.8) return;
      bannerControls.start({ y: 0, opacity: 1 });
      backgroundLogoControls.start({ opacity: 0 });
      inputControls.start({
        y: 300,
        opacity: 0,
        transition: { duration: 0, delay: 0 },
      });
      cursorControls.start({ x: 2000, y: 800, opacity: 0 });
      phoneContainerControls.start({ y: 0 });
    }
  }, [inViewPhone, screenWidth]);

  React.useEffect(() => {
    // 초기 애니메이션
    const logoY = isDesktop ? '-70%' : '-150%';
    if (inView) {
      subLogoControls.start({ y: logoY, opacity: 0 });
      if (scrollYProgress.get() < 0.1) {
        logoControls.start({
          y: logoY,
          opacity: 1,
          scaleX: 0.667,
          scaleY: 0.667,
        });
      }
      formControls.start({
        y: isDesktop ? '85%' : '20%',
        opacity: 1,
      });
      phoneControls.start({
        y: isDesktop ? '20%' : '40%',
        opacity: 1,
        transition: { delay: 0.5, duration: 0.5 },
      });
    } else {
      if (scrollYProgress.get() > 0.1) return;
      formControls.start({ y: 300, opacity: 0 });
      phoneControls.start({ y: 400, opacity: 0 });
    }
  }, [inView, screenWidth]);

  useEffect(() => {
    const updateCursor = () => {
      const progress = scrollYProgress.get();

      // 특정 스크롤 진행률 범위 내에서 커서 이미지를 변경.
      if (progress >= 0.6 && progress < 0.65) {
        setCurrentCursor(true); // 커서 클릭 이미지로 변경
      } else {
        setCurrentCursor(false); // 기본 커서 이미지로 되돌림
      }
    };
    // 스크롤 진행 상태가 변경될 때마다 updateCursor 함수를 호출.
    const unsubscribe = scrollYProgress.onChange(updateCursor);

    return () => unsubscribe(); // 컴포넌트가 언마운트되면 구독을 취소.
  }, [scrollYProgress]);

  return (
    <>
      <div
        ref={sectionRef}
        className={`w-[100vw] h-[150vh] items-center justify-start relative bg-[${themes.grayscale['1f']}]`}
      >
        <div className="flex h-[100vh] sticky top-[56px] justify-center overflow-hidden">
          <motion.div
            animate={bannerControls}
            transition={{ duration: 0.5 }}
            initial={{ y: 0, opacity: 1 }}
            className="responsive-class absolute -left-[126px] desktop:-left-[25vw] flex items-center top-[45px] desktop:top-[2%]"
          >
            <div className="rounded-full opacity-50">
              <div
                className={`bg-black desktop:bg-phone w-[253px] h-[253px] bg-opacity-50 desktop:w-[50vw] desktop:h-[50vw] rounded-full bg-center-1/2 bg-cover`}
              />
            </div>
            <div className="bg-black w-[25vw] h-[25vw] rounded-full hidden desktop:block absolute -right-[25vw] desktop:-right-[25vw]" />
          </motion.div>
          <motion.div
            animate={bannerControls}
            transition={{ duration: 0.5 }}
            initial={{ y: 0, opacity: 1 }}
            className={`responsive-class bg-primary-normal w-[344px] h-[344px] desktop:w-[50vw] desktop:h-[50vw] rounded-full absolute -right-[258px] desktop:-right-[25vw] top-0 desktop:top-[2%] -z-10 `}
          >
            <img
              src={twinkleImage}
              className="hidden desktop:block w-[10vw] desktop:w-[79px] absolute left-[10%] desktop:left-[17%] transform -translate-x-1/2 top-1/4 -z-20"
            />
          </motion.div>
          <div className="z-0 self-center w-[45vw] min-w-[330px] flex flex-col absolute">
            <div
              className={`bg-[${themes.secondary.normal}] rounded-[206px]`}
            />
            <motion.div
              ref={ref}
              animate={bannerControls}
              transition={{ duration: 0.5 }}
              initial={{ y: 0, opacity: 1 }}
              className="responsive-class self-center flex flex-col items-center gap-3"
            >
              <motion.h3
                animate={subLogoControls}
                transition={{ duration: 0.5, delay: 0.5 }}
                initial={{ y: 150, opacity: 1 }}
                className="font-suitBold text-[24px] desktop:text-[56px] text-white self-start desktop:-ml-[90px]"
              >
                새로운,
                <br />
                숏폼의 시작
              </motion.h3>
              <div className="flex flex-col items-center relative">
                <motion.img
                  animate={logoControls}
                  initial={{ y: 150, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  src={bondLogoImage}
                  className="responsive-class w-[216px] desktop:w-[480px] "
                />
              </div>
            </motion.div>
            <motion.div
              animate={bannerControls}
              transition={{ duration: 0.5 }}
              initial={{ y: 300, opacity: 0 }}
              className="responsive-class self-center flex flex-col items-center gap-3 absolute"
            >
              <motion.div
                initial={{ y: 400, opacity: 0 }}
                animate={formControls}
                transition={{ duration: 0.5, delay: 0.5 }}
                className="responsive-class w-full gap-2 flex flex-col justify-center items-center"
              >
                <div className="text-center mb-7 desktop:mb-10 relative text-white font-suitLight w-[350px] desktop:w-[582px] text-[20px] desktop:text-[32px] self-center">
                  <span className="font-suitExtraBold">릴레이 방식</span>의{' '}
                  <span className="font-suitExtraBold">AI 댓영상</span> 숏폼
                  플랫폼
                  <img
                    src={wave}
                    className="w-[48px] desktop:w-[84px] absolute -bottom-1 left-[32px] desktop:-bottom-1.5 desktop:left-[60px] -z-20"
                  />
                </div>
                <div
                  className={`font-suitSemibold ${errors.phoneNumber?.message ? 'text-system-error' : 'text-white'} text-[16px] w-full text-center mb-1 desktop:mb-2 flex items-center justify-center`}
                >
                  {errors.phoneNumber?.message && (
                    <img src={errorIcon} className="w-[24px] mr-2" />
                  )}
                  {errors.phoneNumber?.message ??
                    '출시되면 알려드릴게요! 핸드폰 번호를 남겨보세요.'}
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex items-center justify-center gap-2 w-full mb-3 desktop:mb-0"
                >
                  <input
                    {...register('phoneNumber', {
                      onChange: (e) => {
                        // 입력값을 포맷팅하여 상태 업데이트
                        const formatted = formatPhoneNumber(e.target.value);

                        setValue('phoneNumber', formatted, {
                          shouldValidate: true,
                        });
                      },
                    })}
                    id="phoneNumber"
                    type="text"
                    className="font-suitMedium text-[16px] desktop:text-[24px] w-[224px] desktop:w-[338px] h-[40px] desktop:h-[52px] px-5 py-2 rounded-full border-[1px] border-grayscale-d9 bg-transparent text-grayscale-8c"
                    placeholder="010-1234-5678"
                  />
                  <input type="hidden" {...register('isPrivacyChecked')} />
                  <button
                    type="submit"
                    className="text-center w-[88px] desktop:w-[132px] h-[40px] desktop:h-[52px] font-suitBold text-[16px] px-2.5 desktop:px-5 py-2 desktop:py-3 rounded-full bg-transparent border-[1px] border-grayscale-d9 text-grayscale-white"
                  >
                    SUBMIT
                  </button>
                </form>
                <div
                  onClick={handlePrivacyCheck}
                  className="flex items-center cursor-pointer gap-1"
                >
                  {errors.isPrivacyChecked?.message && (
                    <img src={errorIcon} className="w-[24px]" />
                  )}
                  <span
                    className={` text-[16px] font-suitBold mr-3 ${errors.isPrivacyChecked?.message ? 'text-system-error' : 'text-grayscale-d9'}`}
                  >
                    개인 정보 수집 동의
                  </span>
                  <img
                    src={isPrivacyChecked ? checkboxActiveIcon : checkboxIcon}
                    className="w-[24px]"
                  />
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              animate={phoneContainerControls}
              transition={{ duration: 0.5 }}
              initial={{ y: 0, opacity: 1 }}
              className="responsive-class flex justify-center releative"
            >
              <motion.div
                initial={{ y: 400, opacity: 0 }}
                animate={phoneControls}
                transition={{ duration: 0.5, delay: 1.25 }}
                className="self-center relative"
              >
                <img
                  className="w-[168px] desktop:w-[360px] z-10"
                  src={phoneCard1}
                  alt="Phone Card 1"
                />
                <div className="bg-[#6666ff] flex justify-center items-center rounded-full absolute bottom-0 -left-[25%] desktop:-bottom-2 desktop:-left-[30%] w-[88px] desktop:w-[170px] h-[88px] desktop:h-[170px]">
                  <img
                    src={bondStickerRing}
                    className="spin absolute transform  w-[88px] desktop:w-[174px] h-[88px] desktop:h-[174px]"
                  />
                  <img
                    src={bondLogoWhite}
                    className="w-[42px] desktop:w-[84px]"
                  />
                </div>
                <div
                  style={{ zIndex: -1000 }}
                  className="absolute w-[80vw] max-w-[336px] desktop:max-w-[1520px] top-[50%] desktop:top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <motion.div
                    className="relative"
                    initial={{ opacity: 0 }}
                    animate={backgroundLogoControls}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={twinkleImage}
                      className="w-[40px] desktop:w-[88px] absolute -bottom-[10px] -left-[20px] desktop:-bottom-[40px] desktop:-left-[2vw]"
                    />

                    <img src={bondLogo} />
                  </motion.div>
                </div>
                <motion.img
                  src={twinkleImage}
                  className="w-[40px] desktop:w-[88px] absolute top-[24px] desktop:top-[40px] -left-[20px] desktop:-left-[56px] -z-10"
                  initial={{ opacity: 0 }}
                  animate={backgroundLogoControls}
                  transition={{ duration: 0.5 }}
                />
                <motion.div
                  animate={inputControls}
                  transition={{ duration: 0.5 }}
                  initial={{ opacity: 0, y: 300 }}
                  className="responsive-class flex flex-col gap-4 absolute top-[150%] -right-[50%] desktop:top-[100%] desktop:-right-[25%] min-[1650px]:top-[30%] min-[1650px]:-right-[160%] items-center"
                >
                  <div className="relative flex items-center justify-between w-[337px] desktop:w-[560px] bg-white rounded-[100px] border-[5px] sm:border-[6px] border-solid border-black px-[8px] pl-4 desktop:pl-9 py-2 z-10">
                    <div className="w-[160px] desktop:w-[70%] flex items-center">
                      <div className="font-suitBold text-grayscale-26 text-[24px] desktop:text-[32px] text-center tracking-[0] leading-[normal]">
                        최고의 댄스!
                      </div>
                      <motion.div
                        className="text-[24px] desktop:text-[36px] pb-2 px-0 desktop:px-2 text-grayscale-bf"
                        initial={{ opacity: 1 }}
                        animate={{
                          opacity: [1, 0, 1],
                          transition: {
                            duration: 0.5,
                            repeat: Infinity,
                            repeatType: 'loop',
                          },
                        }}
                      >
                        |
                      </motion.div>
                    </div>
                    <div className=" bg-secondary-light rounded-[100px] px-4 py-2 desktop:py-3.5">
                      <div className="text-secondary-dark text-[16px] desktop:text-[24px] font-suitBold text-center tracking-[0] leading-[normal]">
                        댓영상 생성
                      </div>
                    </div>
                    <motion.img
                      className="w-[10vw] min-w-[85px] desktop:min-w-[200px] z-20 absolute -bottom-[40px] -right-[20px] desktop:-bottom-[80px] desktop:-right-[40px]"
                      animate={cursorControls}
                      initial={{ x: 2000, y: 800, opacity: 0 }}
                      transition={{ duration: 0.5, delay: 0.5 }}
                      src={currentCursor ? cursorClick : cursor}
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <img src={talkIcon} className="w-[17px] desktop:w-[23px]" />
                    <span className="font-suitMedium text-white text-[16px] desktop:text-[24px]">
                      텍스트를 치면 자동 댓영상 생성
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img
                      src={micIcon}
                      className="w-[12px] desktop:w-[16px] mr-[5px] desktop:mr-[7px]"
                    />
                    <span className="font-suitMedium text-white text-[16px] desktop:text-[24px]">
                      목소리를 넣어 자동 댓영상 생성
                    </span>
                  </div>
                </motion.div>
                {!isMobile && (
                  <motion.img
                    animate={bannerControls}
                    initial={{ y: 0, x: '-50%', opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    src={downArrow}
                    className="w-[68px] absolute top-[120%] desktop:top-1/2 left-1/2 transform -translate-x-1/4"
                  />
                )}
              </motion.div>
            </motion.div>
          </div>
        </div>
        <div ref={refFirst} className="h-[50vh]" />
      </div>
      <CardSection
        card2Control={card2Control}
        card3Control={card3Control}
        card4Control={card4Control}
        card5Control={card5Control}
        card1Control={card1Control}
        relayTextXControl={relayTextXControl}
        phoneContainerControls={phoneContainerControls}
        fadeOutCardControl={fadeOutCardControl}
        fadeInTextControl={fadeInTextControl}
        inView={inViewPhone}
      />
    </>
  );
}
