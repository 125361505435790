import React, { useRef } from 'react';
import { motion, useAnimation, useScroll } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import twinkleImage from 'assets/images/twinkle.png';
import firstImage from 'assets/images/1.png';
import secondImage from 'assets/images/2.png';
import thirdImage from 'assets/images/3.png';

export default function VideoDescriptionSection() {
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({ target: sectionRef });
  const controlFirst = useAnimation();
  const controlSecond = useAnimation();
  const controlThird = useAnimation();

  const [refFirst, inViewFirst] = useInView({
    threshold: 0.8,
  });
  const [refSecond, inViewSecond] = useInView({
    threshold: 0.8,
  });
  const [refThird, inViewThird] = useInView({
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inViewFirst) {
      controlFirst.start({ opacity: 1, y: 0, transition: { duration: 0.5 } });
    } else {
      if (scrollYProgress.get() > 0.1) return;
      controlFirst.start({ opacity: 0, y: 100 });
    }
    if (inViewSecond) {
      controlSecond.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 0.2 },
      });
    } else {
      if (scrollYProgress.get() > 0.1) return;
      controlSecond.start({ opacity: 0, y: 100 });
    }
    if (inViewThird) {
      controlThird.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 0.4 },
      });
    } else {
      if (scrollYProgress.get() > 0.1) return;
      controlThird.start({ opacity: 0, y: 100 });
    }
  }, [inViewFirst, inViewSecond, inViewThird, scrollYProgress]);

  return (
    <div
      ref={sectionRef}
      className={`desktop:pb-0 bg-grayscale-1f w-[100vw] h-[150vh] flex items-start justify-center relative`}
    >
      <div className=" h-[100vh] flex justify-center items-center sticky top-[56px] w-[336px] desktop:w-[1080px]">
        <div className="w-full flex flex-col desktop:flex-row desktop:justify-center mb-[80px]">
          <div className="flex flex-col justify-between desktop:min-w-[410px] relative">
            <motion.h3 className="font-suitBold text-white text-left text-[32px] desktop:text-[64px] leading-[45px] desktop:leading-normal">
              댓영상 생성은
              <br />
              세가지 방법으로
            </motion.h3>
            {
              <div className="absolute left-[90%] top-[16px] desktop:top-[540px] desktop:-left-[56px]">
                <img
                  src={twinkleImage}
                  className="w-[36px] desktop:w-[112px] "
                />
                <img
                  src={twinkleImage}
                  className="w-[22px] desktop:w-[56px] absolute -left-[24px] -bottom-[28px] desktop:-left-[40px] desktop:-bottom-[80px]"
                />
              </div>
            }
          </div>
          <div className="pt-[80px] desktop:pt-[248px] inline-flex flex-col items-start desktop:items-end gap-[40px] desktop:gap-[56px] relative self-end desktop:self-start">
            <motion.div
              ref={refFirst}
              animate={controlFirst}
              className="gap-[16px] desktop:gap-[56px] inline-flex items-start relative"
            >
              <div className="w-[32px] desktop:w-[40px]">
                <img
                  className="relative h-[32px] desktop:h-[48px]"
                  alt="Element"
                  src={firstImage}
                />
              </div>
              <div className="w-auto desktop:w-auto min-w-[160px] flex-col gap-[16px] inline-flex items-start relative">
                <p className="w-full relative font-suitBold text-white text-[20px] desktop:text-[32px] leading-[24px] desktop:w-[345px]">
                  댓글 입력으로 AI 영상 생성
                </p>
                <div className="max-w-[260px] desktop:max-w-full min-w-[200px] desktop:min-w-[300px] desktop:w-[454px] inline-flex flex-col items-start">
                  <p className="relative text-grayscale-bf self-stretch font-suitRegular text-[16px] desktop:text-[24px] leading-normal min-w-[260px] desktop:min-w-[400px]">
                    댓글을 입력하면 AI가 자동으로 이미지와
                    <br />
                    목소리를 생성해요, 나의 노출은 줄이고
                    {<br />} 재미있는 댓영상을 제작할 수 있어요
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              ref={refSecond}
              animate={controlSecond}
              initial={{ opacity: 0, y: -100 }}
              className="gap-[16px] desktop:gap-[56px] inline-flex items-start relative"
            >
              <div className="w-[32px] desktop:w-[40px]">
                <img
                  className="relative h-[32px] desktop:h-[48px]"
                  alt="Element"
                  src={secondImage}
                />
              </div>
              <div className="w-auto desktop:w-auto min-w-[160px] flex-col gap-[16px] inline-flex items-start relative">
                <div className="w-full relative font-suitBold text-white text-[24px] desktop:text-[32px] leading-[24px] desktop:w-[345px]">
                  일반 영상
                </div>
                <div className="max-w-[260px] desktop:max-w-full min-w-[200px] desktop:min-w-[300px] desktop:w-[454px] inline-flex flex-col items-start">
                  <p className="relative text-grayscale-bf self-stretch font-suitRegular text-[16px] desktop:text-[24px] leading-normal min-w-[260px] desktop:min-w-[400px]">
                    내가 가지고 있는 기존의 영상으로 <br />
                    업로드 할 수 있어요
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              ref={refThird}
              animate={controlThird}
              className="gap-[16px] desktop:gap-[56px] inline-flex items-start relative"
            >
              <div className="w-[32px] desktop:w-[40px]">
                <img
                  className="relative h-[32px] desktop:h-[48px]"
                  alt="Element"
                  src={thirdImage}
                />
              </div>
              <div className="w-auto desktop:w-auto min-w-[160px] flex-col gap-[16px] inline-flex items-start relative">
                <div className="w-full relative font-suitBold text-white text-[24px] desktop:text-[32px] leading-[24px] desktop:w-[345px]">
                  촬영 영상
                </div>
                <div className="max-w-[260px] desktop:max-w-full min-w-[200px] desktop:min-w-[300px] desktop:w-[454px] inline-flex flex-col items-start">
                  <p className="relative text-grayscale-bf self-stretch font-suitRegular text-[16px] desktop:text-[24px] leading-normal min-w-[260px] desktop:min-w-[400px]">
                    본 컨텐츠를 본 후 생동감 넘치는 <br />
                    리액션 영상을 찍어 업로드 할 수 있어요
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
