import AboutUs from 'pages/AboutUs';
import Banner from 'pages/Banner';
import OurServices from 'pages/OurServices';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

export default function PageRouter() {
  return (
    <Routes>
      {/* <Route path="/our-services" element={<OurServices />} />

      <Route path="/about-us" element={<AboutUs />} /> */}
      <Route path="/" element={<OurServices />} />
    </Routes>
  );
}
