import React, { useState } from 'react';
import handHoldingPhone from 'assets/images/hand-holding-phone.webp';
import useWindowSize from 'hooks/useWindowSize';
import twinkleImage1 from 'assets/icons/twinkleIcon.svg';
import twinkleImage2 from 'assets/icons/twinkleIcon2.svg';
import { formatPhoneNumber } from 'utils/phoneNumberFunc';
import { useForm } from 'react-hook-form';
import { UserMetadataType, schema } from 'utils/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { postUserMetadata } from 'utils/api';
import checkboxIcon from 'assets/icons/checkbox.png';
import checkboxActiveIcon from 'assets/icons/checkbox-active.png';
import errorIcon from 'assets/icons/error.png';

export default function ContactSection() {
  const [screenWidth] = useWindowSize();
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePrivacyCheck = () => {
    setIsPrivacyChecked(!isPrivacyChecked);
    setValue('isPrivacyChecked', !isPrivacyChecked, { shouldValidate: true });
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<UserMetadataType>({
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: '',
      isPrivacyChecked: false,
    },
  });

  const onSubmit = async (formData: UserMetadataType) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await postUserMetadata(formData, 'bottom');
      alert('등록 완료. 출시되면 알려드릴게요!');
      reset({
        phoneNumber: '',
        isPrivacyChecked: false,
      });
      setIsPrivacyChecked(false);
    } catch (error) {
      console.error('POST USER METADATA FAIL : ', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`w-screen h-full desktop:h-auto z-20 flex flex-col justify-center desktop:justify-start items-start desktop:items-center py-[32px] desktop:py-0 desktop:pt-40 bg-neutral-900 max-md:max-w-full relative`}
      >
        <div className="flex items-center desktop:items-start justify-start desktop:justify-between w-[336px] desktop:w-[1080px] relative desktop:gap-[8vw] self-center">
          <div className="flex flex-row-reverse desktop:flex-row gap-6 desktop:gap-8 items-start justify-center relative self-start">
            <div className="flex flex-col self-start mt-12 desktop:mt-0 w-[56px] desktop:absolute -top-[16px] -left-[56px] desktop:-top-[16px] desktop:-left-[80px]">
              <img
                src={twinkleImage2}
                className="w-[34px] desktop:w-[56px] absolute -top-0 right-0 desktop:-top-[72px] desktop:left-12"
              />
              <img src={twinkleImage1} className="w-[34px] desktop:w-[56px]" />
            </div>
            <div className="flex flex-auto gap-5 justify-between self-end mt-0 desktop:text-[40px] max-md:max-w-full mb-0 desktop:mb-[136px]">
              <div className="flex flex-col self-start max-md:max-w-full">
                <div className="text-[32px] desktop:text-[64px] font-suitBold text-white leading-[45px] desktop:leading-[85px] ">
                  곧 만나서
                  <br />
                  재미있게 놀아요
                </div>
                <div className="mt-2 text-[36px] desktop:text-[72px] font-suitBold text-white leading-5 desktop:leading-[90px] ">
                  coming soon
                </div>
                <div className="mt-6 desktop:mt-12 text-[16px] desktop:text-[24px] font-suitMedium text-grayscale-bf max-md:max-w-full">
                  출시되면 알려드릴게요!
                </div>
                <div className="mt-4 text-[16px] desktop:text-[24px] font-suitBold text-white max-md:max-w-full flex items-center flex-wrap">
                  휴대폰 번호
                </div>
                {screenWidth >= 600 && (
                  <div>
                    <form
                      className="flex gap-2 my-4 whitespace-nowrap self-start "
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="relative flex">
                        {errors.phoneNumber?.message && (
                          <img
                            src={errorIcon}
                            className="w-[24px] absolute top-1/2 transform -translate-y-1/2 left-6"
                          />
                        )}
                        <input
                          {...register('phoneNumber', {
                            onChange: (e) => {
                              // 입력값을 포맷팅하여 상태 업데이트
                              const formatted = formatPhoneNumber(
                                e.target.value,
                              );

                              setValue('phoneNumber', formatted, {
                                shouldValidate: true,
                              });
                            },
                          })}
                          id="phoneNumber"
                          type="text"
                          placeholder="010-1234-5678"
                          className={`px-6 py-2 ${errors.phoneNumber?.message ? 'text-system-error pl-14' : 'text-grayscale-8c'} h-[40px] desktop:h-[48px] desktop:min-w-[400px] bg-transparent justify-center items-start text-[16px] desktop:text-[20px] leading-[35px] font-suitMedium border border-solid border-grayscale-d9 rounded-[100px]`}
                        />
                      </div>
                      <input type="hidden" {...register('isPrivacyChecked')} />
                      <button
                        type="submit"
                        className="h-[40px] desktop:h-[48px] text-center desktop:min-w-[128px] justify-center p-2.5 px-4 text-[16px] font-suitBold uppercase border border-solid border-grayscale-d9 rounded-[100px] text-grayscale-d9 "
                      >
                        Submit
                      </button>
                    </form>
                    <div
                      onClick={handlePrivacyCheck}
                      className="flex items-center cursor-pointer gap-1 w-[250px]"
                    >
                      {errors.isPrivacyChecked?.message && (
                        <img src={errorIcon} className="w-[24px]" />
                      )}
                      <span
                        className={`text-[16px] font-suitBold mr-3 ${errors.isPrivacyChecked?.message ? 'text-system-error' : 'text-grayscale-d9'}`}
                      >
                        개인 정보 수집 동의(필수)
                      </span>
                      <img
                        src={
                          isPrivacyChecked ? checkboxActiveIcon : checkboxIcon
                        }
                        className="w-[24px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {screenWidth >= 1080 && (
            <img
              loading="lazy"
              src={handHoldingPhone}
              className="w-[30vw] max-w-[338px] desktop:w-[30vw] self-end justify-self-end"
            />
          )}
        </div>
        {screenWidth < 600 && (
          <div className="self-center max-w-[340px]">
            <form
              className="flex gap-2 my-4 whitespace-nowrap "
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="relative flex">
                {errors.phoneNumber?.message && (
                  <img
                    src={errorIcon}
                    className="w-[24px] absolute top-1/2 transform -translate-y-1/2 left-3"
                  />
                )}
                <input
                  {...register('phoneNumber', {
                    onChange: (e) => {
                      // 입력값을 포맷팅하여 상태 업데이트
                      const formatted = formatPhoneNumber(e.target.value);

                      setValue('phoneNumber', formatted, {
                        shouldValidate: true,
                      });
                    },
                  })}
                  id="phoneNumber"
                  type="text"
                  placeholder="010-1234-5678"
                  className={`${errors.phoneNumber?.message ? 'text-system-error pl-10 pr-2' : 'text-grayscale-8c'} max-w-[240px] justify-center items-start px-[25px] py-2 text-[16px] font-suitMedium bg-transparent border-1 border border-solid border-grayscale-d9 rounded-[100px] `}
                />
              </div>
              <input type="hidden" {...register('isPrivacyChecked')} />
              <button
                type="submit"
                className="w-[88px] flex justify-center items-center p-2.5 text-[16px] font-suitBold uppercase border border-solid border-grayscale-d9 rounded-[100px] text-grayscale-d9 "
              >
                Submit
              </button>
            </form>
            <div
              onClick={handlePrivacyCheck}
              className="flex items-center cursor-pointer gap-1 w-[200px]"
            >
              {errors.isPrivacyChecked?.message && (
                <img src={errorIcon} className="w-[24px]" />
              )}
              <span
                className={` text-[16px] font-suitBold mr-3 ${errors.isPrivacyChecked?.message ? 'text-system-error' : 'text-grayscale-d9'}`}
              >
                개인 정보 수집 동의
              </span>
              <img
                src={isPrivacyChecked ? checkboxActiveIcon : checkboxIcon}
                className="w-[24px]"
              />
            </div>
          </div>
        )}
      </div>

      <div className="w-screen flex justify-center items-center py-5 desktop:py-7 text-base bg-black">
        <div className="flex w-[338px] desktop:w-[1080px] gap-5 justify-start desktop:justify-center max-md:flex-wrap ">
          <div className="flex flex-col self-start w-full">
            <div className="flex justify-between">
              <div className="leading-4 text-[14px] desktop:text-[16px] font-suitBold text-white max-md:max-w-full">
                시프트원(주)
              </div>
              <div className="leading-4 text-[14px] desktop:text-[16px] flex gap-4 desktop:gap-10 justify-between self-end font-suitBold text-white whitespace-nowrap">
                <a
                  href="https://shiftone.notion.site/26ef356930454df78a7136eaac32d33f?pvs=4"
                  target="_blank"
                  className="hover:underline"
                  rel="noreferrer"
                >
                  이용약관
                </a>
                <a
                  href="https://shiftone.notion.site/1f7086fed067410496460522e6cb7426?pvs=4"
                  target="_blank"
                  className="hover:underline"
                  rel="noreferrer"
                >
                  개인정보처리방침
                </a>
              </div>
            </div>

            <div className="flex flex-col desktop:flex-row gap-1 desktop:gap-6 justify-between mt-4 desktop:mt-6 text-stone-300 flex-wrap max-md:max-w-full">
              {screenWidth >= 1080 ? (
                <>
                  <div className="flex justify-between flex-wrap gap-2 desktop:gap-14">
                    <div className="font-suitRegular flex flex-col">
                      <div>서울특별시 강남구 역삼로17길 9 201호 (배영빌딩)</div>
                      <div className="mt-2 xl:mt-1">
                        대표자 : 이근주 , 이태재
                      </div>
                    </div>
                    <div className="font-suitRegular flex flex-col">
                      <div>사업자 번호 : 112-86-01043</div>
                      <div className="mt-2 desktop:mt-1">
                        전화 : 02-2135-1551
                      </div>
                    </div>
                    <div className="font-suitRegular self-start">
                      이메일 : contact@shiftone.kr
                    </div>
                  </div>
                  <div className="font-suitRegular text-[12px] desktop:text-[16px] text-stone-300 self-start">
                    © 2024 Shiftone Co., Ltd. All Rights Reserved.
                  </div>
                </>
              ) : (
                <>
                  <div className="leading-4 font-suitRegular text-[12px]">
                    서울특별시 강남구 역삼로17길 9 201호 (배영빌딩)
                  </div>
                  <div className="flex justify-between">
                    <div className="font-suitRegular flex flex-col text-[12px]">
                      <div className="leading-4 mt-2 desktop:mt-1">
                        대표자 : 이근주 , 이태재
                      </div>
                      <div className="leading-4 mt-2 ">
                        사업자 번호 : 112-86-01043
                      </div>
                    </div>
                    <div className="font-suitRegular flex flex-col text-[12px]">
                      <span className="leading-4 mt-2 desktop:mt-1">
                        전화 : 02-2135-1551
                      </span>
                      <span className="leading-4 mt-2 font-suitRegular self-start">
                        이메일 : contact@shiftone.kr
                      </span>
                    </div>
                  </div>
                  <div className="leading-4 font-suitRegular mt-2 text-[12px] text-stone-300 self-start">
                    © 2024 Shiftone Co., Ltd. All Rights Reserved.
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
