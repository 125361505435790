import axios from 'axios';
import { UserMetadataType } from './validationSchema';

export const fetchClientIp = async () => {
  try {
    // 클라이언트의 IP 주소를 조회.

    const response = await axios.get(process.env.REACT_APP_GET_IP_URL ?? '');

    return response.data;
  } catch (error) {
    console.error('Failed to fetch client IP:', error);
    return '';
  }
};
const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });
// 사용자의 전화번호와 개인정보 수집 동의 여부 서버로 전송
export const postUserMetadata = async (
  data: UserMetadataType,
  collection_position: 'top' | 'bottom',
) => {
  try {
    const { phoneNumber, isPrivacyChecked } = data;
    const clientData = await fetchClientIp();

    const response = await api.post('user/metadata', {
      phone_number: phoneNumber,
      agreed: isPrivacyChecked,
      collection_position,
      consent_version: 'v1',
      country_code: clientData?.country_code ?? '',
      country_name: clientData?.country_name ?? '',
      city: clientData?.state ?? '',
      agent: '',
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
